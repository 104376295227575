// @ts-ignore
export default function initAnimation() {
  (function (s, i, u, o, c, w, d, t, n, x, e, p, a, b) {
    (a = Array.from(d.querySelectorAll('svg#' + i.root)).filter(n => !n.svgatorPlayer)[0] || {}).svgatorPlayer = {
      ready: (function (a) {
        b = [];
        return function (c) {
          return c ? (b.push(c), a.svgatorPlayer) : b
        }
      })(a)
    };
    w[o] = w[o] || {};
    w[o][s] = w[o][s] || [];
    w[o][s].push(i);
    e = d.createElementNS(n, t);
    e.async = true;
    e.setAttributeNS(x, 'href', [u, s, '.', 'j', 's', '?', 'v', '=', c].join(''));
    e.setAttributeNS(null, 'src', [u, s, '.', 'j', 's', '?', 'v', '=', c].join(''));
    p = d.getElementsByTagName(t)[0];
    p.parentNode.insertBefore(e, p);
  })('91c80d77', {
    "root": "enwd0uNMI3D1", "version": "2022-05-04", "animations": [{
      "elements": {
        "enwd0uNMI3D2": {
          "transform": {
            "data": {"s": {"x": 0.321812, "y": 0.321812}, "t": {"x": -207.53138, "y": -202.722948}}, "keys": {
              "o": [{
                "t": 0,
                "v": {"x": 352.576344, "y": -66.121891, "type": "cusp", "end": {"x": 352.576344, "y": -66.121891}},
                "e": [0.42, 0, 1, 1]
              }, {
                "t": 500,
                "v": {
                  "x": 367.281559,
                  "y": 49.935209,
                  "type": "cusp",
                  "start": {"x": 378.494678, "y": 0.016231},
                  "end": {"x": 356.06844, "y": 99.854187}
                },
                "e": [0.42, 0, 1, 1]
              }, {
                "t": 1000,
                "v": {
                  "x": 390.206479,
                  "y": 122.028782,
                  "type": "cusp",
                  "start": {"x": 385.206367, "y": 94.480292},
                  "end": {"x": 406.001967, "y": 154.886561}
                },
                "e": [0.42, 0, 1, 1]
              }, {
                "t": 1500,
                "v": {
                  "x": 430.807414,
                  "y": 194.318251,
                  "type": "cusp",
                  "start": {"x": 427.346209, "y": 178.701582},
                  "end": {"x": 434.268619, "y": 209.93492}
                },
                "e": [0.42, 0, 1, 1]
              }, {
                "t": 2000,
                "v": {
                  "x": 477.736049,
                  "y": 253.463423,
                  "type": "cusp",
                  "start": {"x": 469.427815, "y": 220.065186},
                  "end": {"x": 486.044283, "y": 286.86166}
                },
                "e": [0.42, 0, 1, 1]
              }, {
                "t": 2500,
                "v": {
                  "x": 477.210461,
                  "y": 299.565424,
                  "type": "cusp",
                  "start": {"x": 470.436783, "y": 277.793845},
                  "end": {"x": 483.984138, "y": 321.337004}
                },
                "e": [0.42, 0, 1, 1]
              }, {
                "t": 3000,
                "v": {"x": 504.692687, "y": 338.498577, "type": "cusp", "start": {"x": 504.692687, "y": 338.498577}}
              }],
              "r": [{"t": 400, "v": 0, "e": [0.47, 0, 0.745, 0.715]}, {
                "t": 600,
                "v": 21.9713,
                "e": [0.47, 0, 0.745, 0.715]
              }, {"t": 900, "v": -6.952145, "e": [0.47, 0, 0.745, 0.715]}, {
                "t": 1200,
                "v": 12.905812,
                "e": [0.47, 0, 0.745, 0.715]
              }, {"t": 1400, "v": -15.398185, "e": [0.47, 0, 0.745, 0.715]}, {
                "t": 1700,
                "v": 11.499306,
                "e": [0.47, 0, 0.745, 0.715]
              }, {"t": 2000, "v": -8.4838, "e": [0.47, 0, 0.745, 0.715]}, {
                "t": 2300,
                "v": 10.207419,
                "e": [0.47, 0, 0.745, 0.715]
              }, {"t": 2700, "v": -12.474316, "e": [0.47, 0, 0.745, 0.715]}, {"t": 2900, "v": 1.290564}]
            }
          }, "opacity": [{"t": 1600, "v": 1, "e": [0.47, 0, 0.745, 0.715]}, {"t": 3000, "v": 0}]
        },
        "enwd0uNMI3D10": {
          "transform": {
            "data": {"t": {"x": -466.420502, "y": -338.957001}}, "keys": {
              "o": [{
                "t": 900,
                "v": {"x": 127.675485, "y": -20.017895, "type": "corner"},
                "e": [0.47, 0, 0.745, 0.715]
              }, {
                "t": 1100,
                "v": {"x": 127.44645, "y": 256.193328, "type": "corner"},
                "e": [0.47, 0, 0.745, 0.715]
              }, {
                "t": 1300,
                "v": {
                  "x": 156.511803,
                  "y": 234.096596,
                  "type": "cusp",
                  "start": {"x": 129.224836, "y": 232.736088},
                  "end": {"x": 183.79877, "y": 235.457104}
                },
                "e": [0.47, 0, 0.745, 0.715]
              }, {
                "t": 1500,
                "v": {
                  "x": 178.419203,
                  "y": 269.861806,
                  "type": "cusp",
                  "start": {"x": 173.051336, "y": 256.830021},
                  "end": {"x": 190.127795, "y": 244.970195}
                },
                "e": [0.47, 0, 0.745, 0.715]
              }, {
                "t": 1600,
                "v": {"x": 218.085876, "y": 248.644218, "type": "cusp", "start": {"x": 205.375008, "y": 247.548453}},
                "e": [0.47, 0, 0.745, 0.715]
              }, {
                "t": 1700,
                "v": {
                  "x": 249.712538,
                  "y": 271.595405,
                  "type": "cusp",
                  "start": {"x": 235.317094, "y": 244.977523},
                  "end": {"x": 264.765441, "y": 243.863371}
                },
                "e": [0.47, 0, 0.745, 0.715]
              }, {
                "t": 1800,
                "v": {
                  "x": 306.805795,
                  "y": 239.458761,
                  "type": "cusp",
                  "start": {"x": 288.640856, "y": 238.061459},
                  "end": {"x": 339.642416, "y": 242.602694}
                },
                "e": [0.47, 0, 0.745, 0.715]
              }, {
                "t": 1900,
                "v": {"x": 345.878009, "y": 271.332376, "type": "cusp", "start": {"x": 345.878009, "y": 271.332376}},
                "e": [0.47, 0, 0.745, 0.715]
              }, {
                "t": 2000,
                "v": {
                  "x": 393.501204,
                  "y": 253.433766,
                  "type": "cusp",
                  "start": {"x": 368.182181, "y": 251.581155},
                  "end": {"x": 418.820227, "y": 255.286377}
                },
                "e": [0.47, 0, 0.745, 0.715]
              }, {
                "t": 2100,
                "v": {
                  "x": 424.995598,
                  "y": 271.900177,
                  "type": "cusp",
                  "start": {"x": 424.995598, "y": 271.900177},
                  "end": {"x": 433.042825, "y": 221.796567}
                },
                "e": [0.47, 0, 0.745, 0.715]
              }, {
                "t": 2300,
                "v": {
                  "x": 482.346974,
                  "y": 243.862214,
                  "type": "cusp",
                  "start": {"x": 485.68493, "y": 207.273719},
                  "end": {"x": 481.903241, "y": 256.306167}
                },
                "e": [0.47, 0, 0.745, 0.715]
              }, {
                "t": 2500,
                "v": {"x": 485.644622, "y": 359.838514, "type": "cusp", "start": {"x": 485.644622, "y": 359.838514}}
              }]
            }
          }
        },
        "enwd0uNMI3D11": {
          "transform": {
            "data": {"t": {"x": -264.639503, "y": -302.566002}},
            "keys": {
              "o": [{
                "t": 0,
                "v": {"x": -185.60787, "y": 320.28417, "type": "corner"},
                "e": [0.47, 0, 0.745, 0.715]
              }, {
                "t": 500,
                "v": {"x": 297.674305, "y": 320.28417, "type": "corner"},
                "e": [0.47, 0, 0.745, 0.715]
              }, {"t": 600, "v": {"x": 282.982298, "y": 320.28417, "type": "corner"}, "e": [0.47, 0, 0.745, 0.715]}]
            }
          }
        },
        "enwd0uNMI3D12": {
          "transform": {
            "data": {"t": {"x": -112.793003, "y": -302.0765}},
            "keys": {
              "o": [{
                "t": 1100,
                "v": {"x": 112.793003, "y": 302.0765, "type": "corner"},
                "e": [0.47, 0, 0.745, 0.715]
              }, {
                "t": 1200,
                "v": {"x": 112.793003, "y": 294.0765, "type": "corner"},
                "e": [0.47, 0, 0.745, 0.715]
              }, {"t": 1300, "v": {"x": 112.793003, "y": 302.0765, "type": "corner"}}],
              "k": [{"t": 900, "v": {"x": 25, "y": 0}, "e": [0.42, 0, 1, 1]}, {"t": 1100, "v": {"x": 0, "y": 0}}]
            }
          }
        },
        "enwd0uNMI3D13": {
          "transform": {
            "data": {"t": {"x": -171.784996, "y": -312.60199}},
            "keys": {
              "o": [{
                "t": 1400,
                "v": {"x": 171.784996, "y": 312.60199, "type": "corner"},
                "e": [0.47, 0, 0.745, 0.715]
              }, {
                "t": 1500,
                "v": {"x": 171.784996, "y": 304.60199, "type": "corner"},
                "e": [0.47, 0, 0.745, 0.715]
              }, {"t": 1600, "v": {"x": 171.784996, "y": 312.60199, "type": "corner"}}],
              "k": [{"t": 800, "v": {"x": 25, "y": 0}, "e": [0.42, 0, 1, 1]}, {"t": 1000, "v": {"x": 0, "y": 0}}]
            }
          }
        },
        "enwd0uNMI3D14": {
          "transform": {
            "data": {"t": {"x": -235.590492, "y": -313.091492}},
            "keys": {
              "o": [{
                "t": 1600,
                "v": {"x": 235.590492, "y": 313.091492, "type": "corner"},
                "e": [0.47, 0, 0.745, 0.715]
              }, {
                "t": 1700,
                "v": {"x": 235.590492, "y": 307.091492, "type": "corner"},
                "e": [0.47, 0, 0.745, 0.715]
              }, {"t": 1800, "v": {"x": 235.590492, "y": 313.091492, "type": "corner"}}],
              "k": [{"t": 700, "v": {"x": 25, "y": 0}, "e": [0.42, 0, 1, 1]}, {"t": 900, "v": {"x": 0, "y": 0}}]
            }
          }
        },
        "enwd0uNMI3D15": {
          "transform": {
            "data": {"t": {"x": -325.507004, "y": -313.091492}},
            "keys": {
              "o": [{
                "t": 1800,
                "v": {"x": 325.507004, "y": 313.091492, "type": "corner"},
                "e": [0.47, 0, 0.745, 0.715]
              }, {
                "t": 1900,
                "v": {"x": 325.507004, "y": 307.091492, "type": "corner"},
                "e": [0.47, 0, 0.745, 0.715]
              }, {"t": 2000, "v": {"x": 325.507004, "y": 313.091492, "type": "corner"}}],
              "k": [{"t": 600, "v": {"x": 25, "y": 0}, "e": [0.42, 0, 1, 1]}, {"t": 800, "v": {"x": 0, "y": 0}}]
            }
          }
        },
        "enwd0uNMI3D16": {
          "transform": {
            "data": {"t": {"x": -405.470993, "y": -313.091492}},
            "keys": {
              "o": [{
                "t": 2000,
                "v": {"x": 405.470993, "y": 313.091492, "type": "corner"},
                "e": [0.47, 0, 0.745, 0.715]
              }, {
                "t": 2100,
                "v": {"x": 405.470993, "y": 306.811604, "type": "corner"},
                "e": [0.47, 0, 0.745, 0.715]
              }, {"t": 2200, "v": {"x": 405.470993, "y": 313.091492, "type": "corner"}}],
              "k": [{"t": 500, "v": {"x": 25, "y": 0}, "e": [0.42, 0, 1, 1]}, {"t": 700, "v": {"x": 0, "y": 0}}]
            }
          }
        },
        "enwd0uNMI3D18": {"fill-opacity": [{"t": 800, "v": 0, "e": [0.47, 0, 0.745, 0.715]}, {"t": 2600, "v": 1}]},
        "enwd0uNMI3D21": {
          "stroke-opacity": [{"t": 1200, "v": 1}, {"t": 1400, "v": 0}],
          "stroke-dashoffset": [{"t": 1000, "v": -281.64, "e": [0.47, 0, 0.745, 0.715]}, {"t": 1200, "v": 0}]
        },
        "enwd0uNMI3D22": {
          "stroke-opacity": [{"t": 1600, "v": 1}, {"t": 1800, "v": 0}],
          "stroke-dashoffset": [{"t": 1400, "v": 148.38, "e": [0.47, 0, 0.745, 0.715]}, {"t": 1600, "v": 0}]
        },
        "enwd0uNMI3D23": {
          "stroke-opacity": [{"t": 2000, "v": 1}, {"t": 2200, "v": 0}],
          "stroke-dashoffset": [{"t": 1800, "v": 149.05, "e": [0.47, 0, 0.745, 0.715]}, {"t": 2000, "v": 0}]
        },
        "enwd0uNMI3D24": {
          "stroke-opacity": [{"t": 2400, "v": 1}, {"t": 2600, "v": 0}],
          "stroke-dashoffset": [{"t": 2200, "v": 90.34, "e": [0.47, 0, 0.745, 0.715]}, {"t": 2400, "v": 0}]
        },
        "enwd0uNMI3D26": {
          "stroke-opacity": [{"t": 900, "v": 1}, {"t": 1100, "v": 0}],
          "stroke-dashoffset": [{"t": 700, "v": 281.64, "e": [0.47, 0, 0.745, 0.715]}, {"t": 900, "v": 0}]
        },
        "enwd0uNMI3D27": {
          "stroke-opacity": [{"t": 1100, "v": 1}, {"t": 1300, "v": 0}],
          "stroke-dashoffset": [{"t": 900, "v": 148.38, "e": [0.47, 0, 0.745, 0.715]}, {"t": 1100, "v": 0}]
        },
        "enwd0uNMI3D28": {
          "stroke-opacity": [{"t": 1300, "v": 1}, {"t": 1500, "v": 0}],
          "stroke-dashoffset": [{"t": 1100, "v": 149.05, "e": [0.47, 0, 0.745, 0.715]}, {"t": 1300, "v": 0}]
        },
        "enwd0uNMI3D29": {
          "stroke-opacity": [{"t": 1500, "v": 1}, {"t": 1700, "v": 0}],
          "stroke-dashoffset": [{"t": 1300, "v": 90.34, "e": [0.47, 0, 0.745, 0.715]}, {"t": 1500, "v": 0}]
        },
        "enwd0uNMI3D30": {
          "stroke-opacity": [{"t": 2000, "v": 1}, {"t": 2600, "v": 0}],
          "stroke-dashoffset": [{"t": 700, "v": 697.91, "e": [0.47, 0, 0.745, 0.715]}, {"t": 2000, "v": 0}]
        }
      },
      "s": "MDEA1ZGI5NjBhMAmIzYjA5ZkhiDMlZhN2FkT2FUjNjA3ODcxNmNU2ZTZlNmE2MUGEyYTdFYjBhDM2ExYjJhN2FTkSmFjNjA3ODLZmNmE2MGE3YNjJDYTNWYjA5JZmIyYTdhZGFIjYjE2MDc4NmNY2YTYwYTRHYXTdhYWFhNjA3JODZmNmE2MDlBmYWFiMlRhM2TIwYWM5ZmIyYATNINjBQNzhhKNDlmYWFiMVdJhMzZhNjBiMWRFlYTNhM2EyNOjA3ODZmNmE2SMGE0YWViMTYRwNzg2ZjZlNmRViYg|"
    }], "options": "MDHAxMDgyMjk3YBTdiNjg3OTdiPMjk0MVgyOTcXzNzY2ODZiMjWk4NA|"
  }, 'https://cdn.svgator.com/ply/', '__SVGATOR_PLAYER__', '2024-09-05', window, document, 'script', 'http://www.w3.org/2000/svg', 'http://www.w3.org/1999/xlink')
}
